import React from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Styled } from 'theme-ui'

import Layout from '../components/layout'
import Image from '../components/image'

const HeroImageWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s2}px;

  & img {
    display: block;
  }
`

function BlogPostTemplate({ data }) {
  const blogPost = data.contentfulBlogPost

  const {
    // heroContent,
    // heroMedia,
    content,
    title,
    metaDescription,
    // contentful_id: activePageId,
  } = blogPost

  return (
    <Layout>
      <Helmet
        /**
         * Meta information based on:
         * https://moz.com/blog/meta-data-templates-123
         * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
         */
        title={title}
        meta={[
          {
            property: 'og:title',
            content: title,
          },
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          // heroImage && {
          //   property: 'twitter:image:src',
          //   content: `${seoImage.file.url}?w=1200&h=628&fit=fill`,
          // },
          // heroImage && {
          //   property: 'og:image',
          //   content: `${seoImage.file.url}?w=1200&h=630&fit=fill`,
          // },
        ].filter(Boolean)}
      />
      {blogPost.teaserImage && (
        <HeroImageWrapper>
          <Image
            {...blogPost.teaserImage}
            alt={title}
            fluid={{
              ...blogPost.teaserImage.fluid,
              base64: blogPost.teaserImage.sqip
                ? blogPost.teaserImage.sqip.dataURI
                : blogPost.teaserImage.fluid.base64,
            }}
          />
        </HeroImageWrapper>
      )}
      <Styled.h1>{title}</Styled.h1>
      <MDXProvider>
        <MDXRenderer>{content.childMdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostQuery($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      contentful_id
      slug
      title
      metaDescription
      content {
        childMdx {
          body
        }
      }
      teaserImage {
        contentful_id
        svgContent
        file {
          contentType
          details {
            image {
              height
              width
            }
          }
        }
        sqip(mode: 4, numberOfPrimitives: 42, blur: 0) {
          dataURI
        }
        fluid(maxWidth: 960) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
